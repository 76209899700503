import React from "react";

import { useSelector } from "react-redux";

import Forgot1 from "./Forgot1";
import Forgot2 from "./Forgot2";

import { TbaseReducer } from "../../types/reducersType";

export default function Forgot() {
  const { isForgot } = useSelector((state: TbaseReducer) => state.authReducer);

  return <>{!isForgot ? <Forgot1 /> : <Forgot2 />}</>;
}
