import React from "react";
import "../styles/Dashboard.css";

type DashboardProps = {
  children: JSX.Element;
};

export default function Dashboard({ children }: DashboardProps) {
  return <>{children}</>;
}
