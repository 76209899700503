import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { signout } from "../actions/authAction";

import { useAppDispatch } from "../reducers";

import { TbaseReducer } from "../types/reducersType";

import { useIsMobile } from "../utils/mobile";

export default function Header() {
  const dispatch = useAppDispatch();

  const isMobile = useIsMobile();

  const { name, role, addAd } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  return (
    <header>
      <nav className="navbar">
        <div className="dropdown">
          <button className="dropbtn">{name}</button>
          <div className="dropdown-content">
            {isMobile ? (
              <>
                <Link to="/">Trouver des annonce</Link>
                {addAd ? <Link to="/my-ads">Mes annonces</Link> : null}
                {addAd ? <Link to="/add-ad">Ajouter une annonce</Link> : null}
              </>
            ) : null}
            {role === 1 ? <Link to="/admin">Administration</Link> : null}
            {role === 1 ? <Link to="/my-ads">Mes annonces</Link> : null}
            <Link to="/account">Mon compte</Link>
            <Link to="/" onClick={() => dispatch(signout())}>
              Déconnexion
            </Link>
          </div>
        </div>

        {isMobile ? null : (
          <ul className="nav-links">
            <li>
              <Link to="/">Trouver des annonce</Link>
            </li>
            {addAd ? (
              <li>
                <Link to="/my-ads">Mes annonces</Link>
              </li>
            ) : null}
            {addAd ? (
              <li>
                <Link to="/add-ad">Ajouter une annonce</Link>
              </li>
            ) : null}
          </ul>
        )}
      </nav>
    </header>
  );
}
