import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import Header from "../../components/Header";

import { TbaseReducer } from "../../types/reducersType";

import { deleteAd, getMyAds } from "../../actions/adAction";

import { useAppDispatch } from "../../reducers";

export default function MyAds() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { myAds } = useSelector((state: TbaseReducer) => state.adReducer);

  useEffect(() => {
    dispatch(getMyAds());
  }, [dispatch]);

  return (
    <div className="my-ads-page">
      <Header />

      <main>
        <h1>Mes annonces</h1>
        <div className="ads-list">
          {myAds.map((ad, index) => (
            <div className="ad-item" key={index}>
              <img src={`/api/ad/picture/${ad.photos[0]}`} alt="car" />
              <div className="ad-details">
                <h3>
                  {ad.title}{" "}
                  <i style={{ color: "red", fontWeight: "normal" }}>
                    {ad.dateSchedule
                      ? moment(ad.dateSchedule).isBefore(moment())
                        ? "- L'annonce a expiré"
                        : `- Expire dans ${moment(ad.dateSchedule).diff(
                            moment(),
                            "hours"
                          )} heure(s) et ${
                            moment(ad.dateSchedule).diff(moment(), "minutes") %
                            60
                          } minute(s)`
                      : null}
                  </i>
                </h3>
                <p>{ad.description}</p>
                <div
                  style={{
                    marginTop: "auto",
                    display: "flex",
                    gap: 20,
                  }}
                >
                  <button
                    className="red"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Êtes-vous sûr de vouloir supprimer cette annonce ?"
                        )
                      ) {
                        dispatch(deleteAd(ad.token));
                      }
                    }}
                  >
                    Supprimer
                  </button>
                  <button onClick={() => navigate(`/edit-ad/${ad.token}`)}>
                    Modifier
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}
