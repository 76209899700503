import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Header from "../../components/Header";

import { TbaseReducer } from "../../types/reducersType";
import { TcarCharacteristics } from "../../types/ad/adType";

import { useAppDispatch } from "../../reducers";

import {
  addAd,
  editAd,
  addPicture,
  getAd,
  getReport,
} from "../../actions/adAction";

import { getBrands, getModels } from "../../utils/vehicles";
import { departments } from "../../utils/department";

interface OptionsProps {
  title: string;
  options: string[];
  setOptions: React.Dispatch<React.SetStateAction<string[]>>;
  predefinedOptions: string[];
}

const Options = ({
  title,
  options,
  setOptions,
  predefinedOptions,
}: OptionsProps) => {
  const handleAddOption = (option: string) => {
    setOptions([...options, option]);
  };

  const handleRemoveOption = (option: string) => {
    setOptions(options.filter((opt) => opt !== option));
  };

  return (
    <div className="form-group options-group">
      <h2>{title}</h2>
      <div className="options-list">
        {options.map((option, index) => (
          <div key={index} className="option-item">
            {option}
            <button type="button" onClick={() => handleRemoveOption(option)}>
              x
            </button>
          </div>
        ))}
      </div>
      <input
        type="text"
        placeholder="Ajouter une option"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (e.currentTarget.value.trim() !== "") {
              handleAddOption(e.currentTarget.value);
              e.currentTarget.value = "";
            }
          }
        }}
      />
      <div
        className="predefined-options"
        style={{ flexWrap: "wrap", gap: 10, display: "flex" }}
      >
        <h3 style={{ width: "100%" }}>Options prédéfinies</h3>
        {predefinedOptions.map((option, index) => (
          <button
            style={{ width: "auto" }}
            key={index}
            type="button"
            onClick={() => handleAddOption(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default function AddAd() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { token } = useParams();

  const { photos, ad, reportVehicle } = useSelector(
    (state: TbaseReducer) => state.adReducer
  );

  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [department, setDepartment] = useState("");
  const [description, setDescription] = useState("");
  const [firstname, setFirstname] = useState("");
  const [name, setName] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [formula, setFormula] = useState("");
  const [dateSchedule, setDateSchedule] = useState("");
  const [characteristics, setCharacteristics] = useState<TcarCharacteristics>({
    marque: "",
    modele: "",
    motorisation: "",
    finition: "",
    annee: "",
    kilometrage: "",
    transmission: "",
    fuel: "",
    couleur: "",
    etat: "",
  });
  const [optionsComfort, setOptionsComfort] = useState<string[]>([]);
  const [optionsTechnology, setOptionsTechnology] = useState<string[]>([]);
  const [optionsSecurity, setOptionsSecurity] = useState<string[]>([]);
  const [optionsDesign, setOptionsDesign] = useState<string[]>([]);

  const getFuel = (fuel: string) => {
    if (fuel === "ES") {
      return "Essence";
    } else if (fuel === "GO") {
      return "Diesel";
    } else if (fuel === "EL") {
      return "Électrique";
    } else if (fuel === "EE") {
      return "Hybride";
    }

    return "";
  };

  useEffect(() => {
    if (reportVehicle) {
      setCharacteristics({
        ...characteristics,
        marque: (reportVehicle?.Marque || "").toUpperCase(),
        modele: (reportVehicle?.["Nom commercial"] || "").toUpperCase(),
        fuel: getFuel(reportVehicle?.Energie || ""),
        couleur: reportVehicle?.Couleur || "",
        motorisation: reportVehicle?.["Cylindrée (cm3)"] || "",
      });
    }
  }, [reportVehicle]);

  const handleCharacteristicChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setCharacteristics({
      ...characteristics,
      [e.target.name]: e.target.value,
      ...(e.target.name === "marque" ? { model: "" } : {}),
    });
  };

  const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const fileReaders = files.map((file) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
        });
      });

      Promise.all(fileReaders)
        .then((base64Files) => {
          dispatch({ type: "ADD_PHOTOS", data: base64Files });
        })
        .catch((error) => {
          console.error("Error reading files:", error);
        });
    }
  };

  const handleRemovePhoto = (base64: string, oldToken: string) => {
    dispatch({
      type: "REMOVE_PHOTO",
      data: { base64, oldToken },
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (photos.filter((p) => !p.oldToken).length) {
      photos
        .filter((p) => !p.oldToken)
        .map(async (p) => dispatch(addPicture(p.base64)));
    } else {
      _addAd();
    }
  };

  const handleReorderPhotos = (startIndex: number, endIndex: number) => {
    const result = Array.from(photos);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    dispatch({ type: "SET_PHOTOS", data: result });
  };

  useEffect(() => {
    if (token) {
      dispatch(getAd(token));
    }
  }, [dispatch, token]);

  const _addAd = async () => {
    const departmentCode = departments.filter(
      (element) => element.dep_name === department
    )[0].num_dep;

    if (token) {
      await dispatch(
        editAd(
          token,
          title,
          price,
          department,
          description,
          characteristics,
          optionsComfort,
          optionsTechnology,
          optionsSecurity,
          optionsDesign,
          photos
            .filter((p) => p.oldToken || p.token)
            .map((p) => (p.oldToken ? p.oldToken : p.token || "")),
          dateSchedule
        )
      );

      navigate("/my-ads");
    } else {
      await dispatch(
        addAd(
          title,
          price,
          department,
          departmentCode,
          description,
          characteristics,
          optionsComfort,
          optionsTechnology,
          optionsSecurity,
          optionsDesign,
          photos.filter((p) => p.token).map((p) => p.token || ""),
          dateSchedule
        )
      );
    }

    navigate("/my-ads");
  };

  useEffect(() => {
    if (
      photos.filter((p) => !p.oldToken).length &&
      photos.filter((p) => !p.oldToken).length ===
        photos.filter((p) => p.token).length
    ) {
      _addAd();
    }
  }, [dispatch, photos]);

  useEffect(() => {
    if (ad && token) {
      setTitle(ad.title);
      setPrice(ad.price);
      setDepartment(ad.department);
      setDescription(ad.description);
      setCharacteristics(ad.characteristics);
      setOptionsComfort(ad?.options.comfort);
      setOptionsTechnology(ad?.options.technology);
      setOptionsSecurity(ad?.options.security);
      setOptionsDesign(ad?.options.design);
      setDateSchedule(ad.dateSchedule);
      dispatch({
        type: "SET_PHOTOS",
        data: ad.photos
          .filter((p) => p)
          .map((p) => ({
            oldToken: p,
          })),
      });
    }
  }, [ad]);

  useEffect(() => {
    if (!token) {
      dispatch({ type: "SET_PHOTOS", data: [] });
      setCharacteristics({
        marque: "",
        modele: "",
        motorisation: "",
        finition: "",
        annee: "",
        kilometrage: "",
        transmission: "",
        fuel: "",
        couleur: "",
        etat: "",
      });
      setOptionsComfort([]);
      setOptionsTechnology([]);
      setOptionsSecurity([]);
      setOptionsDesign([]);
      setTitle("");
      setPrice("");
      setDepartment("");
      setDescription("");
      setFirstname("");
      setName("");
      setLicensePlate("");
      setFormula("");
      setDateSchedule("");
    }
  }, [token]);

  return (
    <div className="add-ad-page">
      <Header />
      <main>
        <h1>{token ? "Modifier" : "Ajouter"} une annonce</h1>
        <form onSubmit={handleSubmit} className="add-ad-form">
          <div className="form-group">
            <label>Titre de l'annonce*</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <label>Prix</label>
            <input
              type="text"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <label>Département</label>
            <select
              value={department}
              name="department"
              onChange={(e) => setDepartment(e.target.value)}
            >
              <option value="">Sélectionner un département</option>
              {departments.map((department, idx) => (
                <option key={idx} value={department.dep_name}>
                  {department.dep_name} ({department.num_dep})
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Description de l'annonce*</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            ></textarea>
          </div>

          <div className="form-group">
            <label>Nom</label>
            <input
              type="text"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <label>Prénom</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label>Immatriculation</label>
            <input
              type="text"
              value={licensePlate}
              onChange={(e) => setLicensePlate(e.target.value)}
            />
            <label>Numéro de formule</label>
            <input
              type="text"
              value={formula}
              onChange={(e) => setFormula(e.target.value)}
            />
            <button
              onClick={() => {
                dispatch(getReport(firstname, name, licensePlate, formula));
              }}
            >
              Synchroniser les données
            </button>
          </div>

          <div className="form-group characteristics-group">
            <h2>Caractéristique du véhicule*</h2>
            <select
              value={characteristics.marque}
              name="marque"
              onChange={handleCharacteristicChange}
            >
              <option value="">Sélectionner une marque</option>
              {getBrands().map((brand, idx) => (
                <option key={idx} value={brand}>
                  {brand}
                </option>
              ))}
            </select>
            <select
              value={characteristics.modele}
              name="modele"
              onChange={handleCharacteristicChange}
            >
              <option value="">Sélectionner un modèle</option>
              {getModels(characteristics.marque).map((brand, idx) => (
                <option key={idx} value={brand}>
                  {brand}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Motorisation"
              name="motorisation"
              value={characteristics.motorisation}
              onChange={handleCharacteristicChange}
              required
            />
            <input
              type="text"
              placeholder="Finition"
              name="finition"
              value={characteristics.finition}
              onChange={handleCharacteristicChange}
              required
            />
            <input
              type="text"
              placeholder="Année"
              name="annee"
              value={characteristics.annee}
              onChange={handleCharacteristicChange}
              required
            />
            <input
              type="text"
              placeholder="Kilométrage"
              name="kilometrage"
              value={characteristics.kilometrage}
              onChange={handleCharacteristicChange}
              required
            />
            <select
              value={characteristics.transmission}
              name="transmission"
              onChange={handleCharacteristicChange}
            >
              <option value="">Sélectionner une transmission</option>
              <option value="Automatique">Automatique</option>
              <option value="Manuelle">Manuelle</option>
            </select>
            <select
              value={characteristics.fuel}
              name="fuel"
              onChange={handleCharacteristicChange}
            >
              <option value="">Sélectionner un carburant</option>
              <option value="Essence">Essence</option>
              <option value="Diesel">Diesel</option>
              <option value="Électrique">Électrique</option>
              <option value="Hybride">Hybride</option>
            </select>
            <select
              value={characteristics.couleur}
              name="couleur"
              onChange={handleCharacteristicChange}
              required
            >
              <option value="">Sélectionner une couleur</option>
              <option value="Blanc">Blanc</option>
              <option value="Rouge">Rouge</option>
              <option value="Rose">Rose</option>
              <option value="Orange">Orange</option>
              <option value="Jaune">Jaune</option>
              <option value="Vert">Vert</option>
              <option value="Bleu">Bleu</option>
              <option value="Violet">Violet</option>
              <option value="Brun">Brun</option>
              <option value="Gris">Gris</option>
              <option value="Noir">Noir</option>
            </select>
            <select
              value={characteristics.etat}
              name="etat"
              onChange={handleCharacteristicChange}
              required
            >
              <option value="">Sélectionner un état</option>
              <option value="Mauvais">Mauvais</option>
              <option value="Bon">Bon</option>
              <option value="Très bon">Très bon</option>
              <option value="Neuf">Neuf</option>
            </select>
          </div>
          <Options
            title="Équipement et options (Confort)"
            options={optionsComfort}
            setOptions={setOptionsComfort}
            predefinedOptions={[
              "Sièges chauffants",
              "Climatisation automatique",
              "Sièges en cuir",
              "Support lombaire",
            ]}
          />
          <Options
            title="Équipement et options (Technologie)"
            options={optionsTechnology}
            setOptions={setOptionsTechnology}
            predefinedOptions={[
              "Bluetooth",
              "Système de navigation",
              "Écran tactile",
              "Chargeur sans fil",
            ]}
          />
          <Options
            title="Équipement et options (Sécurité)"
            options={optionsSecurity}
            setOptions={setOptionsSecurity}
            predefinedOptions={[
              "Aide au stationnement",
              "Freinage d'urgence automatique",
              "Détection des angles morts",
              "Caméra de recul",
            ]}
          />
          <Options
            title="Équipement et options (Style)"
            options={optionsDesign}
            setOptions={setOptionsDesign}
            predefinedOptions={[
              "Toit ouvrant",
              "Jantes en alliage",
              "Éclairage d'ambiance",
              "Sellerie personnalisée",
            ]}
          />
          <div className="form-group photos-group">
            <h2>Ajouter des photos*</h2>
            <input type="file" multiple onChange={handlePhotoUpload} />
            <div className="photos-list">
              {photos.map((photo, index) => (
                <div key={index} className="photo-item">
                  <img
                    src={photo.base64 || `/api/ad/picture/${photo.oldToken}`}
                    alt={`uploaded ${index}`}
                  />
                  <div className="button-container">
                    <button
                      type="button"
                      onClick={() =>
                        handleRemovePhoto(photo.base64, photo.oldToken || "")
                      }
                    >
                      x
                    </button>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => handleReorderPhotos(index, index - 1)}
                      >
                        ↑
                      </button>
                    )}
                    {index < photos.length - 1 && (
                      <button
                        type="button"
                        onClick={() => handleReorderPhotos(index, index + 1)}
                      >
                        ↓
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <label>Compte à rebours</label>
          <select
            value={dateSchedule}
            name="dateSchedule"
            onChange={(e) => setDateSchedule(e.target.value)}
          >
            <option value="">Pas à compte à rebours</option>
            <option value="24">24h</option>
            <option value="48">48h</option>
            <option value="72">72h</option>
          </select>

          <button type="submit">
            {token ? "Modifier" : "Poster"} l'annonce
          </button>
        </form>
      </main>
    </div>
  );
}
