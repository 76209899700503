import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import { forgotPassword } from "../../actions/authAction";

import { TbaseReducer } from "../../types/reducersType";

export default function Forgot() {
  const dispatch = useAppDispatch();

  const { isForgotError } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const [email, setEmail] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle forgot password logic, e.g., send a reset link to the email
    dispatch(forgotPassword(email));
  };

  return (
    <div className="forgot-password-container">
      <h2>Réinitialiser votre mot de passe</h2>
      <p>
        Veuillez saisir l'adresse Email affiliée à votre compte afin que nous
        puissions vous envoyer la procédure de réinitialisation de votre mot de
        passe.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit">Suivant</button>
      </form>
      {isForgotError && (
        <p className="error-message">Une erreur est survenue</p>
      )}
      <div style={{ marginTop: 10 }}>
        <Link to="/">← revenir à la page de connexion</Link>
      </div>
    </div>
  );
}
