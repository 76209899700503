import { TauthInitialState } from "../types/auth/authType";

export const initialState = {
  isAuthenticated: false,
  isError: false,
  isSuccess: false,
  isErrorUpdateAccountPassword: false,
  isForgot: false,
  isForgotError: false,
  userId: null,
  name: "",
  email: "",
  phone: "",
  siret: "",
  role: 0,
  addAd: false,
  notifications: [],
} satisfies TauthInitialState;

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "REFRESH_TOKEN":
      return {
        ...state,
        isAuthenticated: action.success,
        userId: action.data ? action.data.userId : null,
        name: action.data ? action.data.userName : "",
        email: action.data ? action.data.userEmail : "",
        phone: action.data ? action.data.userPhone : "",
        siret: action.data ? action.data.userSiret : "",
        notifications: action.data ? action.data.userNotifications : "",
        role: action.data ? action.data.userRole : 0,
        addAd: action.data ? action.data.userAddAd : false,
      };
    case "SIGNIN":
      return {
        ...state,
        isAuthenticated: action.success,
        isError: !action.success,
        userId: action.data ? action.data.userId : null,
        name: action.data ? action.data.userName : "",
        email: action.data ? action.data.userEmail : "",
        phone: action.data ? action.data.userPhone : "",
        siret: action.data ? action.data.userSiret : "",
        notifications: action.data ? action.data.userNotifications : "",
        role: action.data ? action.data.userRole : 0,
        addAd: action.data ? action.data.userAddAd : false,
      };
    case "SIGNOUT":
      return {
        ...state,
        isAuthenticated: false,
      };
    case "UPDATE_NAME":
      return {
        ...state,
        name: action.data,
      };
    case "UPDATE_EMAIL":
      return {
        ...state,
        email: action.data,
      };
    case "UPDATE_PHONE":
      return {
        ...state,
        phone: action.data,
      };
    case "UPDATE_SIRET":
      return {
        ...state,
        siret: action.data,
      };
    case "UPDATE_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.data,
      };
    case "UPDATE_ACCOUNT_PASSWORD":
      return {
        ...state,
        isErrorUpdateAccountPassword: !action.success,
      };
    case "FORGOT_PASSWORD":
      return {
        ...state,
        isForgot: !!action.success,
        isForgotError: !action.success,
      };
    default:
      return state;
  }
};
