export const getCurrencyValue = (value: number, digits = 2) => {
  if (value == null) {
    return "";
  }

  const optionsCurrency = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };

  // @ts-ignore
  return new Intl.NumberFormat("fr-FR", optionsCurrency).format(value);
};

export const getValue = (value: number, digits = 2) => {
  const options = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };

  return new Intl.NumberFormat("fr-FR", options).format(value);
};
