import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Header from "../../components/Header";

import { useAppDispatch } from "../../reducers";

import { TbaseReducer } from "../../types/reducersType";

import {
  updateAccount,
  updateAccountNotifications,
  updateAccountPassword,
} from "../../actions/authAction";

import { departments } from "../../utils/department";

export default function MyAccount() {
  const dispatch = useAppDispatch();

  const {
    name,
    email,
    phone,
    siret,
    isErrorUpdateAccountPassword,
    notifications,
  } = useSelector((state: TbaseReducer) => state.authReducer);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    dispatch(updateAccount(name, email, phone, siret));
  };

  const handleSubmitNotications = (e: React.FormEvent) => {
    e.preventDefault();

    dispatch(updateAccountNotifications(notifications));
  };

  const handleSubmitPassword = (e: React.FormEvent) => {
    e.preventDefault();

    if (newPassword === confirmPassword) {
      dispatch(updateAccountPassword(oldPassword, newPassword));
    } else {
      setError("Les mots de passe de sont pas identique");
    }
  };

  useEffect(() => {
    if (isErrorUpdateAccountPassword) {
      setError("Une erreur est survenue");
    } else {
      setError("");
    }
  }, [isErrorUpdateAccountPassword]);

  return (
    <div className="my-account-page">
      <Header />

      <main>
        <h1>Mon compte</h1>
        <form onSubmit={handleSubmit} className="account-form">
          <div className="form-group">
            <label>Nom</label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_NAME",
                  data: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="form-group">
            <label>Adresse email</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_EMAIL",
                  data: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="form-group">
            <label>N° téléphone</label>
            <input
              type="phone"
              name="phone"
              value={phone}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_PHONE",
                  data: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="form-group">
            <label>SIRET</label>
            <input
              type="siret"
              name="siret"
              value={siret}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_SIRET",
                  data: e.target.value,
                })
              }
              required
            />
          </div>
          <button type="submit">Modifier</button>
        </form>
        <h1>Notifications</h1>
        <form onSubmit={handleSubmitNotications} className="account-form">
          <div
            style={{
              display: "flex",
              gap: 10,
              flexWrap: "wrap",
              marginBottom: 10,
            }}
          >
            {departments.map((department, key) => (
              <label style={{ whiteSpace: "nowrap" }}>
                <input
                  type="checkbox"
                  onClick={() => {
                    let tmpNotifications: string[] = [];

                    if (notifications.includes(department.num_dep)) {
                      tmpNotifications = notifications.filter(
                        (num) => num !== department.num_dep
                      );
                    } else {
                      tmpNotifications = [...notifications, department.num_dep];
                    }

                    dispatch({
                      type: "UPDATE_NOTIFICATIONS",
                      data: tmpNotifications,
                    });
                  }}
                  checked={notifications.includes(department.num_dep)}
                />
                {department.dep_name} ({department.num_dep})
              </label>
            ))}
          </div>
          <button type="submit">Modifier</button>
        </form>
        <h1>Mot de passe</h1>
        <form onSubmit={handleSubmitPassword} className="password-form">
          <div className="form-group">
            <label>Ancien mot de passe</label>
            <input
              type="password"
              name="oldPassword"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
            <label>Nouveau mot de passe</label>
            <input
              type="password"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <label>Confirmer le mot de passe</label>
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Modifier</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </main>
    </div>
  );
}
