import React from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import { TbaseReducer } from "../../types/reducersType";

import { getAds } from "../../actions/adAction";

import { getBrands, getModels } from "../../utils/vehicles";
import { departments } from "../../utils/department";

export default function Filters() {
  const dispatch = useAppDispatch();

  const { filters } = useSelector((state: TbaseReducer) => state.adReducer);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    dispatch({
      type: "UPDATE_FILTERS",
      data: {
        name: e.target.name,
        value: e.target.value,
      },
    });
  };

  return (
    <>
      <div className="search-bar">
        <input
          value={filters.search}
          name="search"
          type="text"
          placeholder="Marque, modèle, année, kilométrage..."
          onChange={handleChange}
        />
        <button onClick={() => dispatch(getAds(filters))}>Rechercher</button>
      </div>
      <div className="filters">
        <select
          value={filters.department}
          name="department"
          onChange={handleChange}
        >
          <option value="">Tous les départements</option>
          {departments.map((department, idx) => (
            <option key={idx} value={department.dep_name}>
              {department.dep_name} ({department.num_dep})
            </option>
          ))}
        </select>
        <div className="filter-group">
          <select value={filters.brand} name="brand" onChange={handleChange}>
            <option value="">Toutes les marques</option>
            {getBrands().map((brand, idx) => (
              <option key={idx} value={brand}>
                {brand}
              </option>
            ))}
          </select>
          <select value={filters.model} name="model" onChange={handleChange}>
            <option value="">Tous les modèles</option>
            {getModels(filters.brand).map((model, idx) => (
              <option key={idx} value={model}>
                {model}
              </option>
            ))}
          </select>
          <select
            value={filters.gearbox}
            name="gearbox"
            onChange={handleChange}
          >
            <option value="">Toutes les transmissions</option>
            <option value="Automatique">Automatique</option>
            <option value="Manuelle">Manuelle</option>
          </select>
          <select value={filters.fuel} name="fuel" onChange={handleChange}>
            <option value="">Tous les carburants</option>
            <option value="Essence">Essence</option>
            <option value="Diesel">Diesel</option>
            <option value="Électrique">Électrique</option>
            <option value="Hybride">Hybride</option>
          </select>
        </div>
        <div className="filter-group">
          <input
            value={filters.mileageMin}
            name="mileageMin"
            type="number"
            placeholder="Km min"
            onChange={handleChange}
          />
          <input
            value={filters.mileageMax}
            name="mileageMax"
            type="number"
            placeholder="Km max"
            onChange={handleChange}
          />
          <input
            value={filters.yearMin}
            name="yearMin"
            type="number"
            placeholder="Année min"
            onChange={handleChange}
          />
          <input
            value={filters.yearMax}
            name="yearMax"
            type="number"
            placeholder="Année max"
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}
