import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useAppDispatch } from "../../reducers";

import { signin } from "../../actions/authAction";

import { TbaseReducer } from "../../types/reducersType";

import LogoCFP from "../../images/LogoCFP";

export default function Signin() {
  const dispatch = useAppDispatch();

  const { isError } = useSelector((state: TbaseReducer) => state.authReducer);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRemember, setIsRemember] = useState(false);
  const [error, setError] = useState("");

  const _signin = (e: React.FormEvent) => {
    e.preventDefault();

    dispatch(signin(email, password, isRemember));
  };

  useEffect(() => {
    if (isError) {
      setError("L'identifiant ou le mot de passe est incorrect");
    }
  }, [isError]);

  return (
    <div style={{ flexDirection: "column", display: "flex", gap: 20 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 700,
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            marginTop: 20,
            marginBottom: 20,
            justifyContent: "center",
            alignItems: "center",
            width: 300,
          }}
        >
          <LogoCFP />
        </div>
        <p>
          Ne perdez plus de temps avec les enchères interminables et les
          transactions incertaines. Avec CarsForPro, entrez en contact
          directement avec les concessionnaires pour obtenir les véhicules dont
          vous avez besoin, sans intermédiaire. Simplifiez vos achats, gagnez du
          temps, et accédez à une sélection de véhicules de qualité, en toute
          transparence. Avec CarsForPro, c'est vous qui maîtrisez vos
          acquisitions.
        </p>
        <p>
          Bienvenue de retour sur CarsForPro, veuillez vous connecter pour
          accéder à votre espace personnel.
        </p>
      </div>
      <div className="login-container">
        <h2>Connexion</h2>

        <form onSubmit={_signin}>
          <div className="input-container">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="options">
            <label>
              <input
                type="checkbox"
                onClick={() => setIsRemember(!isRemember)}
                checked={isRemember}
              />
              Se souvenir de moi
            </label>
            {/* <Link to="/forgot">Mot de passe oublié</Link> */}
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit">Se connecter</button>
          <div
            style={{
              display: "flex",
              gap: 10,
              marginTop: 10,
              justifyContent: "center",
            }}
          >
            <Link to={"/forgot"} className="back-link">
              Mot de passe oublié
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              gap: 10,
              marginTop: 10,
            }}
          >
            <Link to={"/legal"} className="back-link">
              Mentions légales
            </Link>
            <Link to={"/condition"} className="back-link">
              Conditions d'utilisations
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
