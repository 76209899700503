import { TofferInitialState } from "../types/offer/offerType";

export const initialState = {
  offers: [],
} satisfies TofferInitialState;

export const offerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET_OFFERS":
      return {
        ...state,
        offers: action.success ? action.data : [],
      };
    case "UPDATE_OFFER":
      let tmpOffers = JSON.parse(JSON.stringify(state.offers));

      for (let index = 0; index < tmpOffers.length; index++) {
        const element = tmpOffers[index];

        if (element.id === action.body.offerId) {
          element.status = action.body.status;
        }
      }

      return {
        ...state,
        offers: tmpOffers,
      };
    default:
      return state;
  }
};
