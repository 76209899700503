import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import Loader from "./components/Loader";

import Dashboard from "./layouts/Dashboard";
import Auth from "./layouts/Auth";

import Signin from "./views/auth/Signin";
import Forgot from "./views/auth/Forgot";
import Home from "./views/dashboard/Home";
import Ad from "./views/dashboard/Ad";
import AddAd from "./views/dashboard/AddAd";
import MyAds from "./views/dashboard/MyAds";
import Admin from "./views/dashboard/Admin";
import MyAccount from "./views/dashboard/MyAccount";

import Legal from "./views/auth/Legal";
import Condition from "./views/auth/Condition";

import { useAppDispatch } from "./reducers";

import { refreshToken } from "./actions/authAction";

import { TbaseReducer } from "./types/reducersType";

function App() {
  const dispatch = useAppDispatch();

  const { isAuthenticated, userId } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const { isLoad } = useSelector((state: TbaseReducer) => state.loadReducer);

  useEffect(() => {
    if (!userId) {
      dispatch(refreshToken());
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(refreshToken());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/legal"
            element={
              <Auth>
                <Legal />
              </Auth>
            }
          />
          <Route
            path="/condition"
            element={
              <Auth>
                <Condition />
              </Auth>
            }
          />
          {!isAuthenticated ? (
            <>
              <Route
                path="*"
                element={
                  <Auth>
                    <Signin />
                  </Auth>
                }
              />
              <Route
                path="/forgot"
                element={
                  <Auth>
                    <Forgot />
                  </Auth>
                }
              />
            </>
          ) : (
            <>
              <Route
                path="/"
                element={
                  <Dashboard>
                    <Home />
                  </Dashboard>
                }
              />
              <Route
                path="/ad/:token"
                element={
                  <Dashboard>
                    <Ad />
                  </Dashboard>
                }
              />
              <Route
                path="/add-ad"
                element={
                  <Dashboard>
                    <AddAd />
                  </Dashboard>
                }
              />
              <Route
                path="/edit-ad/:token"
                element={
                  <Dashboard>
                    <AddAd />
                  </Dashboard>
                }
              />
              <Route
                path="/my-ads"
                element={
                  <Dashboard>
                    <MyAds />
                  </Dashboard>
                }
              />
              <Route
                path="/admin"
                element={
                  <Dashboard>
                    <Admin />
                  </Dashboard>
                }
              />
              <Route
                path="/account"
                element={
                  <Dashboard>
                    <MyAccount />
                  </Dashboard>
                }
              />
            </>
          )}
        </Routes>
      </BrowserRouter>
      {isLoad ? <Loader /> : null}
    </>
  );
}

export default App;
