export default function Condition() {
  return (
    <div
      style={{ margin: "20px", fontFamily: "Arial, sans-serif", color: "#333" }}
    >
      <h1 style={{ textAlign: "center", color: "#0073e6" }}>
        Conditions d'Utilisation
      </h1>

      <h2>1. Acceptation des Conditions</h2>
      <p>
        En accédant et en utilisant le site CarsForPro.fr, vous acceptez les
        présentes conditions d'utilisation sans réserve ni restriction. Si vous
        n'acceptez pas ces conditions, veuillez ne pas utiliser ce site.
      </p>

      <h2>2. Modifications des Conditions</h2>
      <p>
        CarsForPro.fr se réserve le droit de modifier les présentes conditions
        d'utilisation à tout moment. Les modifications seront effectives dès
        leur publication sur le site. Il est de votre responsabilité de
        consulter régulièrement les conditions d'utilisation pour vous tenir
        informé des changements éventuels.
      </p>

      <h2>3. Accès au Site</h2>
      <p>
        CarsForPro.fr s'efforce de maintenir le site accessible à tout moment,
        mais ne peut garantir un accès continu et ininterrompu. Des
        interruptions temporaires peuvent survenir pour des raisons techniques,
        de maintenance ou pour toute autre raison.
      </p>

      <h2>4. Utilisation du Contenu</h2>
      <p>
        Tout le contenu du site, y compris les textes, images, vidéos, logos, et
        autres éléments, est protégé par les droits de propriété intellectuelle.
        Vous êtes autorisé à consulter, télécharger, et imprimer le contenu du
        site pour un usage personnel et non commercial, à condition de ne pas
        modifier le contenu et de conserver toutes les mentions de droits
        d'auteur et autres mentions de propriété.
      </p>

      <h2>5. Comportement de l'Utilisateur</h2>
      <p>
        Vous vous engagez à ne pas utiliser le site CarsForPro.fr pour :
        <ul>
          <li>
            Transmettre tout contenu illégal, nuisible, menaçant, abusif,
            harcelant, diffamatoire, vulgaire, obscène ou autrement
            répréhensible.
          </li>
          <li>Violer les droits de propriété intellectuelle d'autrui.</li>
          <li>
            Engager des activités de spam, y compris la publicité non sollicitée
            ou les sollicitations commerciales.
          </li>
          <li>
            Collecter ou stocker des données personnelles d'autres utilisateurs
            sans leur consentement.
          </li>
        </ul>
      </p>

      <h2>6. Limitation de Responsabilité</h2>
      <p>
        CarsForPro.fr ne pourra être tenu responsable des dommages directs ou
        indirects résultant de l'utilisation ou de l'incapacité à utiliser le
        site, y compris en cas de virus ou autres éléments nuisibles.
      </p>

      <h2>7. Liens Externes</h2>
      <p>
        Le site peut contenir des liens vers des sites tiers. CarsForPro.fr n'a
        aucun contrôle sur ces sites et décline toute responsabilité quant à
        leur contenu ou leur fonctionnement. L'accès à ces sites se fait à vos
        propres risques.
      </p>

      <h2>8. Protection des Données Personnelles</h2>
      <p>
        Vos données personnelles sont collectées et traitées conformément à
        notre politique de confidentialité. En utilisant ce site, vous consentez
        à ce traitement.
      </p>

      <h2>9. Droit Applicable et Juridiction</h2>
      <p>
        Les présentes conditions d'utilisation sont régies par le droit
        français. En cas de litige, les tribunaux de Paris seront seuls
        compétents.
      </p>

      <h2>10. Contact</h2>
      <p>
        Pour toute question concernant ces conditions d'utilisation, vous pouvez
        nous contacter à l'adresse suivante :
        <a href="mailto:contact@carsforpro.fr">contact@carsforpro.fr</a>.
      </p>
    </div>
  );
}
