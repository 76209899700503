import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import Header from "../../components/Header";

import { Tad, TcarListing } from "../../types/ad/adType";

import { useAppDispatch } from "../../reducers";

import { getAd } from "../../actions/adAction";
import { addOffer } from "../../actions/offerAction";

import { TbaseReducer } from "../../types/reducersType";

import { getCurrencyValue } from "../../utils/general";
import { useIsMobile } from "../../utils/mobile";

interface PriceItemProps {
  ad: TcarListing;
  price: string;
  setPrice: React.Dispatch<React.SetStateAction<string>>;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  token: string | undefined;
  dispatch: ReturnType<typeof useAppDispatch>;
}

const PriceItem = ({
  ad,
  price,
  setPrice,
  comment,
  setComment,
  token,
  dispatch,
}: PriceItemProps) => {
  const [isOffer, setIsOffer] = useState(false);

  const formattedPrice = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(parseInt(ad.price || price));

  return (
    <div className="ad-price-section">
      <div className="ad-price-container">
        {ad.price ? (
          <div className="ad-price">{getCurrencyValue(parseInt(ad.price))}</div>
        ) : null}

        {isOffer ? (
          <span style={{ maxWidth: 260 }}>Votre offre a été déposée</span>
        ) : (
          <>
            {ad.offerId && ad.offerStatus !== 1 ? (
              <span style={{ maxWidth: 260 }}>
                Vous avez déjà déposé une offre le{" "}
                <b>{moment(ad.offerDate).format("DD/MM/YYYY HH:MM:ss")}</b>
              </span>
            ) : (
              <>
                {ad.price ? null : (
                  <>
                    <p style={{ marginTop: 0, maxWidth: 320 }}>
                      Le prix de ce véhicule n’est pas fixé, et nous vous
                      invitons à nous faire une offre. Si ce véhicule vous
                      intéresse, proposez un montant qui vous semble juste.
                      Toutes les propositions seront soigneusement étudiées, et
                      nous vous répondrons rapidement.
                    </p>
                    <label>Mon offre</label>
                    <input
                      style={{ width: "100%" }}
                      type="number"
                      value={price}
                      name="price"
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </>
                )}
                <button
                  style={{ whiteSpace: "nowrap" }}
                  onClick={() => {
                    if (ad.price || price) {
                      if (
                        window.confirm(
                          `Vous êtes sur le point d'acheter le véhicule pour ${formattedPrice}. Cliquer sur "OK" vous engage`
                        )
                      ) {
                        dispatch(
                          addOffer(
                            token || "",
                            ad.price ? parseInt(ad.price) : parseFloat(price),
                            comment
                          )
                        );

                        setIsOffer(true);
                      }
                    } else {
                      alert("Veuillez renseigner un prix");
                    }
                  }}
                >
                  Acheter
                </button>
                <i>Une commission de 250 € HT sera appliquée</i>
              </>
            )}
          </>
        )}
      </div>
      <div className="ad-price-container">
        <label>Envoyer un message</label>
        <textarea
          style={{ width: "100%" }}
          value={comment}
          name="comment"
          onChange={(e) => setComment(e.target.value)}
        />
        <button
          style={{ whiteSpace: "nowrap" }}
          onClick={() => {
            dispatch(addOffer(token || "", 0, comment));
            alert("Votre message a bien été envoyé");
          }}
        >
          Envoyer
        </button>
      </div>
    </div>
  );
};

export default function Ad() {
  let { token } = useParams();

  const isMobile = useIsMobile();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ads, ad } = useSelector((state: TbaseReducer) => state.adReducer);

  const [currentPicture, setCurrentPicture] = useState(0);
  const [price, setPrice] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (token) {
      dispatch(getAd(token));
    }
  }, [dispatch, token]);

  if (!ad) {
    return null;
  }

  return (
    <div className="ad-detail-page">
      <Header />

      <main>
        <Link to={".."} className="back-link">
          ← revenir à la page des annonces
        </Link>

        <div className="ad-detail-container">
          {isMobile ? (
            <PriceItem
              ad={ad}
              price={price}
              setPrice={setPrice}
              comment={comment}
              setComment={setComment}
              token={token}
              dispatch={dispatch}
            />
          ) : null}
          <div className="ad-detail-main">
            <div className="ad-images">
              <img
                src={`/api/ad/picture/${ad.photos[currentPicture]}`}
                alt="main"
              />
              <div className="ad-thumbnails">
                {ad.photos.map((token, index) => (
                  <img
                    onClick={() => setCurrentPicture(index)}
                    key={index}
                    src={`/api/ad/picture/${token}`}
                    alt={`thumbnail-${index}`}
                  />
                ))}
              </div>
            </div>
            {isMobile ? null : (
              <PriceItem
                ad={ad}
                price={price}
                setPrice={setPrice}
                comment={comment}
                setComment={setComment}
                token={token}
                dispatch={dispatch}
              />
            )}
          </div>

          <div className="ad-description">
            <h1>{ad.title}</h1>
            <p>{ad.department}</p>
            <p>{ad.description}</p>
          </div>

          <div className="ad-characteristics">
            <h2>Caractéristiques</h2>
            <table>
              <tbody>
                {Object.entries(ad.characteristics).map(([key, value]) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="ad-options">
            <h2>Équipements et options</h2>
            <div className="option-category">
              <h3>Confort</h3>
              <ul>
                {ad.options.comfort.map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            </div>
            <div className="option-category">
              <h3>Technologie</h3>
              <ul>
                {ad.options.technology.map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            </div>
            <div className="option-category">
              <h3>Sécurité</h3>
              <ul>
                {ad.options.security.map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            </div>
            <div className="option-category">
              <h3>Design</h3>
              <ul>
                {ad.options.design.map((option, index) => (
                  <li key={index}>{option}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="similar-ads">
          <h2>Ces annonces peuvent vous intéresser</h2>
          <div className="similar-ads-list">
            {ads.slice(0, 4).map((ad: Tad, index: number) => (
              <div className="similar-ad-item" key={index}>
                <img src={`/api/ad/picture/${ad.photos[0]}`} alt="similar ad" />
                <div className="similar-ad-details">
                  <h3>{ad.title}</h3>
                  <p></p>
                  <button onClick={() => navigate(`/ad/${ad.token}`)}>
                    voir l'annonce
                  </button>
                </div>
              </div>
            ))}
          </div>
          <Link to="/" className="view-all-link">
            Voir toutes les annonces
          </Link>
        </div>
      </main>
    </div>
  );
}
