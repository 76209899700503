export default function Legal() {
  return (
    <div
      style={{
        margin: "20px",
        fontFamily: "Arial, sans-serif",
        color: "#333",
      }}
    >
      <h1 style={{ textAlign: "center", color: "#0073e6" }}>
        Mentions Légales
      </h1>

      <h2>Informations Générales</h2>
      <p>
        <strong>Nom du site :</strong> CarsForPro.fr
      </p>
      <p>
        <strong>Propriétaire :</strong> LAHMER AUTOMOBILES
      </p>
      <p>
        <strong>SIREN :</strong> 833 913 775
      </p>
      <p>
        <strong>Adresse :</strong> 7 C RUE DU BOIS GRAND, 31140 MONTBERON, FRANCE
      </p>
      <p>
        <strong>Email :</strong> contact@carsforpro.fr
      </p>
      <h2>Hébergement</h2>
      <p>
        <strong>Hébergeur :</strong> OVH SAS
      </p>
      <p>
        <strong>Adresse :</strong> 2 Rue Kellermann, 59100 Roubaix, France
      </p>
      <p>
        <strong>Téléphone :</strong> +33 9 72 10 10 07
      </p>

      <h2>Responsabilité</h2>
      <p>
        La société LAHMER AUTOMOBILES s'efforce d'assurer l'exactitude et la mise à jour
        des informations diffusées sur ce site. Toutefois, elle décline toute
        responsabilité en cas d'erreurs, d'inexactitudes ou d'omissions.
        L'utilisateur est seul responsable de l'utilisation des informations
        fournies sur ce site.
      </p>

      <h2>Droits d'Auteur</h2>
      <p>
        Tout le contenu du site CarsForPro.fr, y compris les textes, images,
        vidéos, et logos, est protégé par le droit d'auteur. Toute reproduction,
        distribution ou modification de ces éléments est interdite sans
        l'autorisation préalable de la société LAHMER AUTOMOBILES.
      </p>

      <h2>Protection des Données Personnelles</h2>
      <p>
        Conformément à la loi n° 78-17 du 6 janvier 1978 relative à
        l'informatique, aux fichiers et aux libertés, vous disposez d'un droit
        d'accès, de rectification et de suppression des données personnelles
        vous concernant. Pour exercer ce droit, vous pouvez nous contacter par
        email à l'adresse suivante :{" "}
        <a href="mailto:contact@carsforpro.fr">contact@carsforpro.fr</a>.
      </p>

      <h2>Litiges</h2>
      <p>
        Tout litige en relation avec l'utilisation du site CarsForPro.fr est
        soumis au droit français. Il est fait attribution exclusive de
        juridiction aux tribunaux compétents de Paris.
      </p>
    </div>
  );
}
