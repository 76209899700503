import { combineReducers } from "redux";
import { useDispatch } from "react-redux";

import { authReducer } from "./authReducer";
import { adReducer } from "./adReducer";
import { loadReducer } from "./loadReducer";
import { adminReducer } from "./adminReducer";
import { offerReducer } from "./offerReducer";

import { AppDispatch } from "../store";

const config = {
  authReducer,
  adReducer,
  loadReducer,
  adminReducer,
  offerReducer,
};

const appReducer = combineReducers(config);
export const useAppDispatch: () => AppDispatch = useDispatch;

export default appReducer;
