import React, { useEffect } from "react";

import Header from "../../components/Header";

import { useAppDispatch } from "../../reducers";

import { getAccounts } from "../../actions/adminAction";
import { getOffers } from "../../actions/offerAction";

import Offers from "../../components/admin/Offers";
import Accounts from "../../components/admin/Accounts";

export default function Admin() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAccounts());
    dispatch(getOffers());
  }, [dispatch]);

  return (
    <div className="my-ads-page">
      <Header />

      <main>
        <Offers />
        <Accounts />
      </main>
    </div>
  );
}
