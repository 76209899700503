import { Link } from "react-router-dom";

export default function Forgot() {
  return (
    <div className="email-sent-container">
      <h2>Email envoyé</h2>
      <p>
        Un mail a été envoyé à votre adresse Email, vous y trouverez votre
        nouveau mot de passe. Nous vous conseillons de le modifier.
      </p>
      <div style={{ marginTop: 10 }}>
        <Link to="/">← revenir à la page de connexion</Link>
      </div>
    </div>
  );
}
