import { TadminInitialState, Taccount } from "../types/admin/adminType";

export const initialState = {
  accounts: [],
} satisfies TadminInitialState;

export const adminReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET_ACCOUNTS":
      return {
        ...state,
        accounts: action.data,
      };
    case "DELETE_ACCOUNT":
      return {
        ...state,
        accounts: state.accounts.filter(
          (account: Taccount) => account.id !== action.body.id
        ),
      };
    default:
      return state;
  }
};
