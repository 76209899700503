import { TloadInitialState } from "../types/load/loadType";

export const initialState = {
  isLoad: 0,
} satisfies TloadInitialState;

export const loadReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "START_LOADER":
      return {
        ...state,
        isLoad: state.isLoad + 1,
      };
    case "END_LOADER":
      return {
        ...state,
        isLoad: state.isLoad - 1,
      };

    default:
      return state;
  }
};
