type TBrands = {
  [key: string]: string[];
};

const brands: TBrands = {
  AUDI: [
    "100",
    "200",
    "80",
    "90",
    "A1",
    "A2",
    "A3",
    "A4",
    "A5",
    "A6",
    "A6/S6",
    "A7",
    "A8",
    "ALLROAD",
    "CABRIOLET",
    "COUPE",
    "E-TRON",
    "Q2",
    "Q3",
    "Q5",
    "Q7",
    "Q8",
    "R8",
    "RS3",
    "RS4",
    "RS5",
    "RS6",
    "RS7",
    "RS Q8",
    "S3",
    "S4",
    "S4 AVANT",
    "S4 CABRIOLET",
    "S5",
    "S5 CABRIOLET",
    "S7",
    "S8",
    "SQ5",
    "SQ7",
    "TT",
    "TTS",
    "V8",
    "AUTRES",
  ],
  BMW: [
    "316",
    "318",
    "320",
    "323",
    "324",
    "325",
    "518",
    "520",
    "524",
    "525",
    "528",
    "530",
    "535",
    "540",
    "628",
    "635",
    "728",
    "730",
    "732",
    "735",
    "740",
    "745",
    "750",
    "840",
    "850",
    "CLASSE S",
    "I3",
    "I8",
    "M2",
    "M3",
    "M4",
    "M5",
    "M535",
    "M6",
    "M635",
    "SERIE 1",
    "SERIE 2",
    "SERIE 3",
    "SERIE 4",
    "SERIE 5",
    "SERIE 6",
    "SERIE 7",
    "SERIE 8",
    "X1",
    "X2",
    "X3",
    "X4",
    "X5",
    "X6",
    "X7",
    "Z-SERIES",
    "Z1",
    "Z3",
    "Z4",
    "Z8",
    "ZI CABRIOLET",
    "AUTRES",
  ],
  CITROEN: [
    "2CV",
    "AX",
    "AXEL",
    "BERLINGO",
    "BX",
    "C-CROSSER",
    "C-ELYSEE",
    "C-ZERO",
    "C1",
    "C15",
    "C2",
    "C25",
    "C25D",
    "C25E",
    "C25TD",
    "C3",
    "C3 AIRCROSS",
    "C3 PICASSO",
    "C4",
    "C4 PICASSO",
    "C5",
    "C6",
    "C8",
    "COMBI",
    "CX",
    "DS3",
    "DS4",
    "DS5",
    "DYANE",
    "EVASION",
    "GSA",
    "JUMPER",
    "JUMPY",
    "LNA",
    "MEHARI",
    "E-MEHARI",
    "NEMO COMBI",
    "PICASSO",
    "SAXO",
    "SPACETOURER",
    "VISA",
    "XANTIA",
    "XM",
    "XSARA",
    "ZX",
    "AUTRES",
  ],
  FIAT: [
    "124",
    "124 SPIDER",
    "126",
    "127",
    "131",
    "132",
    "238",
    "500",
    "600",
    "900",
    "900.E",
    "ARGENTA",
    "BARCHETTA",
    "BERTONE",
    "BRAVA",
    "BRAVO",
    "CINQUECENTO",
    "COUPE",
    "CROMA",
    "DOBLO",
    "DUCATO",
    "FIORINO",
    "FREEMONT",
    "FULLBACK VP",
    "GRANDE PUNTO",
    "IDEA",
    "MAREA",
    "MULTIPLA",
    "PALIO",
    "PANDA",
    "PUNTO",
    "PUNTO EVO",
    "REGATA",
    "RITMO",
    "RITMO 2",
    "RITMO 3",
    "SCUDO",
    "SEDICI",
    "SEICENTO",
    "SPIDER",
    "STILO",
    "TALENTO",
    "TALENTO VP",
    "TEMPRA",
    "TIPO",
    "ULYSSE",
    "UNO",
    "X1-9",
    "AUTRES",
  ],
  FORD: [
    "AEROSTAR",
    "B-MAX",
    "C-MAX",
    "CAPRI",
    "COUGAR",
    "COURRIER",
    "CUSTOM",
    "ECOSPORT",
    "EDGE",
    "ESCORT",
    "EXPLORER",
    "FIESTA",
    "FOCUS",
    "FUSION",
    "GALAXY",
    "GRANADA",
    "GRAND C-MAX",
    "GT",
    "KA",
    "KA+",
    "KOMBI",
    "KUGA",
    "MAVERICK",
    "MINIBUS",
    "MONDEO",
    "MUSTANG",
    "ORION",
    "PROBE",
    "PUMA",
    "S-MAX",
    "SCORPIO",
    "SIERRA",
    "STREETKA",
    "TAUNUS",
    "TOURNEO",
    "TRANSIT",
    "AUTRES",
  ],
  "MERCEDES-BENZ": [
    "190-SERIES",
    "200",
    "220",
    "230",
    "240",
    "250",
    "260",
    "280",
    "300",
    "350",
    "400",
    "500",
    "560",
    "AMG",
    "AMG GT",
    "CLS",
    "CITAN",
    "CITAN COMBI",
    "CLASSE A",
    "CLASSE B",
    "CLASSE C",
    "CLASSE CL",
    "CLASSE CLA",
    "CLASSE CLK",
    "CLASSE CLS",
    "CLASSE E",
    "CLASSE G",
    "CLASSE GLE",
    "CLASSE GL",
    "CLASSE GLA",
    "CLASSE GLK",
    "CLASSE M",
    "CLASSE R",
    "CLASSE S",
    "CLASSE SL ROADSTER",
    "CLASSE V",
    "EQC",
    "GLB",
    "GLC",
    "GLS",
    "MB",
    "ML",
    "SLC",
    "SLS",
    "SL",
    "SLK",
    "SLR",
    "SLS AMG",
    "SPRINTER",
    "V",
    "VANEO",
    "VIANO",
    "VITO",
    "AUTRES",
  ],
  OPEL: [
    "ADAM",
    "AGILA",
    "AMPERA",
    "ANTARA",
    "ASCONA",
    "ASTRA",
    "CABRIO",
    "CALIBRA",
    "CASCADA",
    "COMBO",
    "COMMODORE",
    "CORSA",
    "CROSSLAND X",
    "FRONTERA",
    "GRANDLAND X",
    "GT",
    "INSIGNIA",
    "KADETT",
    "KARL",
    "MANTA",
    "MERIVA",
    "MOKKA",
    "MONTEREY",
    "MONZA",
    "MOVANO",
    "OMEGA",
    "REKORD",
    "SENATOR",
    "SIGNUM",
    "SINTRA",
    "SPEEDSTER",
    "TIGRA",
    "VECTRA",
    "VIVARO",
    "ZAFIRA",
    "AUTRES",
  ],
  PEUGEOT: [
    "1007",
    "104",
    "106",
    "107",
    "108",
    "2008",
    "205",
    "206",
    "207",
    "208",
    "3008",
    "305",
    "306",
    "307",
    "308",
    "309",
    "4007",
    "4008",
    "405",
    "406",
    "407",
    "5008",
    "505",
    "508",
    "604",
    "605",
    "607",
    "806",
    "807",
    "BIPPER TEPEE",
    "BOXER",
    "CABRIOLET",
    "COMBI",
    "COUPE",
    "EXPERT",
    "EXPERT COMBI",
    "ION",
    "J5",
    "J5 COMBI",
    "PARTNER",
    "RCZ",
    "RIFTER",
    "TRAVELLER",
    "AUTRES",
  ],
  RENAULT: [
    "ALPINE",
    "ARKANA",
    "AVANTIME",
    "CAPTUR",
    "CHAMADE",
    "CLIO",
    "ESPACE",
    "EXPRESS",
    "FLUENCE",
    "FUEGO",
    "KADJAR",
    "KANGOO",
    "KOLEOS",
    "LAGUNA",
    "LATITUDE",
    "MASTER",
    "MEGANE",
    "MEGANESEDAN",
    "MICROBUS",
    "MODUS",
    "NEVADA",
    "NEVADA.PHASE.2",
    "R11",
    "R12",
    "R13",
    "R14",
    "R18",
    "R19",
    "R20",
    "R21",
    "R25",
    "R30",
    "R4",
    "R5",
    "R6",
    "R9",
    "SAFRANE",
    "SCENIC",
    "SPIDER",
    "SPORT SPIDER",
    "SUPERCINQ",
    "TALISMAN",
    "TRAFIC",
    "TWINGO",
    "TWIZY",
    "VEL SATIS",
    "WIND",
    "ZOE",
    "AUTRES",
  ],
  VOLKSWAGEN: [
    "ARTEON",
    "BEETLE",
    "BORA",
    "CAB.",
    "CABRIOLET",
    "CADDY",
    "CALIFORNIA",
    "CARAVELLE",
    "COCCINELLE",
    "COMBI",
    "CORRADO",
    "CRAFTER COMBI",
    "EOS",
    "FOX",
    "GOLF",
    "GOLF PLUS",
    "GOLF SPORTSVAN",
    "JETTA",
    "LT COMBI",
    "LUPO",
    "MINIBUS",
    "MULTIVAN",
    "NEW BEETLE",
    "PASSAT",
    "PHAETON",
    "POLO",
    "SANTANA",
    "SCIROCCO",
    "SHARAN",
    "T-CROSS",
    "T-ROC",
    "TIGUAN",
    "TIGUAN ALLSPACE",
    "TOUAREG",
    "TOURAN",
    "TRANSPORTER",
    "UP",
    "VARIANT",
    "VENTO",
    "AUTRES",
  ],
  ABARTH: [
    "ABARTH 124 SPIDER",
    "ABARTH 500",
    "ABARTH GRANDE PUNTO",
    "ABARTH PUNTO EVO",
    "AUTRES",
  ],
  AIXAM: [
    "300",
    "400",
    "400.4",
    "500",
    "500.4",
    "500.5",
    "A540",
    "A550",
    "A721",
    "A741",
    "A751",
    "AIXAM",
    "CITY",
    "CITY S",
    "COUPÉ",
    "CROSSLINE",
    "CROSSOVER",
    "ECO",
    "ECO 4 PLACES",
    "GTO",
    "LUXE",
    "LUXE 4 PLACES",
    "MAC",
    "MAC 300",
    "MAC 340",
    "MAC 400",
    "MAC 500",
    "MINAUTO",
    "MINAUTO GT",
    "MINIVAN",
    "PICK-UP",
    "ROADLINE",
    "SCOUTY",
    "STANDART",
    "SUPER LUXE",
    "SUPER LUXE 2000",
    "SUPER LUXE 4 PL.",
    "UT 2 PLACES",
    "D-TRUCK",
    "AUTRES",
  ],
  ALEKO: ["2141", "AUTRES"],
  "ALFA ROMEO": [
    "145",
    "146",
    "147",
    "155",
    "156",
    "159",
    "164",
    "166",
    "33",
    "4C",
    "6",
    "75",
    "8C",
    "8C SPIDER",
    "90",
    "ALFA",
    "ALFA-6",
    "ALFASUD",
    "ALFETTA",
    "BRERA",
    "COUPE",
    "CROSSWAGON Q4",
    "GT",
    "GIULIA",
    "GIULIETTA",
    "GTV",
    "MITO",
    "SPIDER",
    "SPORT-WAGON",
    "SPORTWAGON Q4",
    "SPRINT",
    "STELVIO",
    "SUD",
    "ZAGATO",
    "AUTRES",
  ],
  ALPINA: [
    "3-SERIES",
    "7/8-SERIES",
    "B10",
    "B11",
    "B12",
    "B3",
    "B4",
    "B5",
    "B6",
    "B7",
    "B9",
    "C1",
    "C2",
    "D3",
    "D5",
    "SERIE 3 TOURING",
    "SERIE 5",
    "XD3 BITURBO",
    "AUTRES",
  ],
  ALPINE: ["A110", "AUTRES"],
  "ALPINE-RENAULT": ["A610", "COUPE", "AUTRES"],
  "AMERICAN-MOTORS": ["CONCORD", "SPIRIT", "EAGLE", "AUTRES"],
  ARO: [
    "10",
    "24",
    "ARO 10 TRAPEURS",
    "ARO 24",
    "ARO 244 FORESTER",
    "CARPAT",
    "CROSS LANDER",
    "FORESTER",
    "SPARTANA",
    "TRAPEURS",
    "AUTRES",
  ],
  ARTEGA: ["ARTEGA", "AUTRES"],
  "ASTON MARTIN": [
    "CYGNET",
    "DB11",
    "DB7",
    "DB9",
    "DBS",
    "LAGONDA",
    "RAPIDE",
    "V8",
    "VANQUISH",
    "VANTAGE",
    "VIRAGE",
    "AUTRES",
  ],
  AUSTIN: [
    "INNOCENTI",
    "MG",
    "MAESTRO",
    "METRO",
    "MINI",
    "MONTEGO",
    "MORRIS",
    "PRINCESS",
    "AUTRES",
  ],
  AUTOBIANCHI: ["A112", "Y", "Y10", "AUTRES"],
  AUVERLAND: ["A3", "A4", "AUTRES"],
  BEDFORD: ["MIDI", "AUTRES"],
  "BEDFORD-GME": ["MIDI", "AUTRES"],
  BELLIER: [
    "B8",
    "DIVANE",
    "DIVANE II",
    "DOCKER CITY",
    "JADE",
    "JADE CLASSIC",
    "JADE RACING",
    "JADE SO GOOD",
    "JADE URBAN",
    "LE BELLIER",
    "OPALE",
    "VX 350",
    "VX 550",
    "VX 650",
    "VX VENDÉE GLOBE",
    "E-DOCKER CITY",
    "E-JADE",
    "AUTRES",
  ],
  BENTLEY: [
    "ARNAGE",
    "AZURE",
    "BENTAYGA",
    "BROOKLANDS",
    "CONTINENTAL",
    "COUPE",
    "EIGHT",
    "FLYING SPUR",
    "MULSANNE",
    "TURBO",
    "AUTRES",
  ],
  BERTONE: ["FREECLIMBER", "AUTRES"],
  "BLUECAR GROUPE BOLLORE": ["BLUECAR", "BLUESUMMER", "AUTRES"],
  BUIC: ["CENTURY", "PARK", "SKYLARCK", "AUTRES"],
  BUICK: ["PARK AVENUE", "AUTRES"],
  CADILLAC: [
    "ALLANTE",
    "ATS",
    "BLS",
    "CT6",
    "CTS",
    "DEVILLE",
    "ELDORADO",
    "ESCALADE",
    "FLEETWOOD",
    "SEVILLE",
    "SRX",
    "STS",
    "XT5",
    "XLR",
    "AUTRES",
  ],
  CASALINI: ["DIVA", "KERRY", "M10", "M12", "M20", "PICKUP", "WRS", "AUTRES"],
  CATERHAM: ["SUPERSEVEN", "AUTRES"],
  CHATENET: ["BAROODER", "CH", "MEDIA", "SPEEDINO", "STELLA", "AUTRES"],
  CHEVROLET: [
    "ALERO",
    "AVEO",
    "BLAZER",
    "CAMARO",
    "CAPTIVA",
    "CORSICA",
    "CORVETTE",
    "CRUZE",
    "EPICA",
    "EVANDA",
    "KALOS",
    "LACETTI",
    "MALIBU",
    "MATIZ",
    "NUBIRA",
    "ORLANDO",
    "REZZO",
    "SPARK",
    "TAHOE",
    "TRANSSPORT",
    "TRAX",
    "VOLT",
    "AUTRES",
  ],
  "CHEVROLET USA": [
    "BERETTA",
    "CAPRICE",
    "CITATION",
    "TRAILBLAZER",
    "Z 28",
    "AUTRES",
  ],
  CHRYSLER: [
    "300C",
    "300M",
    "CROSSFIRE",
    "ES",
    "GRAND VOYAGER",
    "LE BARON",
    "NEON",
    "NEW YORKER",
    "PT CRUISER",
    "SARATOGA",
    "SEBRING",
    "STRATUS",
    "VIPER",
    "VISION",
    "VOYAGER",
    "AUTRES",
  ],
  CORVETTE: ["CORVETTE", "AUTRES"],
  CUPRA: ["ATECA", "AUTRES"],
  DACIA: ["DOKKER", "DUSTER", "LODGY", "LOGAN", "SANDERO", "AUTRES"],
  DAEWOO: [
    "ESPERO",
    "EVANDA",
    "KALOS",
    "KORANDO",
    "LACETTI",
    "LANOS",
    "LEGANZA",
    "MATIZ",
    "MUSSO",
    "NEXIA",
    "NUBIRA",
    "REXTON",
    "REZZO",
    "TACUMA",
    "AUTRES",
  ],
  DAIHATSU: [
    "APPLAUSE",
    "CHARADE",
    "COPEN",
    "CUORE",
    "DOMINO",
    "FEROZA",
    "GRAN MOVE",
    "MATERIA",
    "MOVE",
    "ROCKY",
    "SIRION",
    "TERIOS",
    "TREVIS",
    "YRV",
    "AUTRES",
  ],
  DALLAS: ["TORPEDO", "AUTRES"],
  DANGEL: [
    "505",
    "BERLINGO",
    "C25",
    "COMBI",
    "EXPERT",
    "JUMPY",
    "PARTNER",
    "SCUDO",
    "AUTRES",
  ],
  DATSUN: [
    "BLUEBIRD",
    "CEDRIC",
    "CHERRY",
    "MICRA",
    "PRAIRIE",
    "SILVIA",
    "STANZA",
    "SUNNY",
    "VANETTE",
    "AUTRES",
  ],
  "DE LA CHAPELLE": [
    "55",
    "ATALANTE",
    "CABRIOLET",
    "ROADSTER",
    "TOURER",
    "AUTRES",
  ],
  DODGE: ["AVENGER", "CALIBER", "JOURNEY", "NITRO", "VIPER", "AUTRES"],
  DONKERVOORT: ["D8", "AUTRES"],
  DR: ["DR ZERO", "AUTRES"],
  DS: ["DS3", "DS4", "DS5", "DS7", "AUTRES"],
  DUE: ["DYNAMIC", "INITIALE", "PACK", "PREMIUM", "AUTRES"],
  FERRARI: [
    "308",
    "328",
    "348",
    "400",
    "412",
    "456",
    "458",
    "458 ITALIA",
    "458 SPECIALE",
    "458 SPIDER",
    "488",
    "575",
    "599",
    "612",
    "812 SUPERFAST",
    "BB 512",
    "CALIFORNIA",
    "ENZO",
    "F12",
    "F355",
    "F360",
    "F430",
    "F550",
    "FF",
    "GTC4LUSSO",
    "MONDIAL",
    "PORTOFINO",
    "TESTAROSSA",
    "AUTRES",
  ],
  FISKER: ["KARMA", "AUTRES"],
  "FORD USA": ["MUSTANG", "AUTRES"],
  FSO: ["CARO", "AUTRES"],
  "GENERAL MOTORS": ["125", "1300", "MONTANA", "POLONEZ", "AUTRES"],
  GME: ["MIDI", "MINIBUS", "AUTRES"],
  GRECAV: ["EKE", "SONIQUE", "AUTRES"],
  HOMMELL: ["BERLINETTE", "AUTRES"],
  HONDA: [
    "ACCORD",
    "AERODECK",
    "CRX",
    "CIVIC",
    "CONCERTO",
    "CR-V",
    "CR-Z",
    "FR-V",
    "HONDA E",
    "HR-V",
    "INSIGHT",
    "INTEGRA",
    "JAZZ",
    "LEGEND",
    "LOGO",
    "NSX",
    "PRELUDE",
    "QUINTET",
    "S2000",
    "SHUTTLE",
    "STREAM",
    "AUTRES",
  ],
  HUMMER: ["H1", "H2", "H3", "AUTRES"],
  HYUNDAI: [
    "ACCENT",
    "ATOS",
    "AZERA",
    "COUPE",
    "ELANTRA",
    "GALLOPER",
    "GENESIS",
    "GETZ",
    "GRANDEUR",
    "I10",
    "I20",
    "I30",
    "I40",
    "IONIQ",
    "IX20",
    "IX35",
    "IX55",
    "KONA",
    "LANTRA",
    "MATRIX",
    "NEXO",
    "PONY",
    "SANTA FE",
    "SATELLITE",
    "SCOUPE",
    "SONATA",
    "TERRACAN",
    "TRAJET",
    "TUCSON",
    "VELOSTER",
    "XG",
    "AUTRES",
  ],
  INFINITI: [
    "EX",
    "FX",
    "G",
    "G37",
    "M",
    "M37",
    "Q30",
    "Q50",
    "Q60",
    "Q70",
    "QX30",
    "QX50",
    "AUTRES",
  ],
  INNOCENTI: [
    "500",
    "650",
    "900",
    "990",
    "B54",
    "B55",
    "MINI",
    "MINIMATIC",
    "S",
    "SE",
    "SL",
    "TURBO",
    "AUTRES",
  ],
  ISUZU: ["D-MAX", "TROOPER", "TROOPER COURT", "TROOPER LONG", "AUTRES"],
  IVECO: ["DAILY COMBI", "AUTRES"],
  JAGUAR: [
    "DAIMLER",
    "E-PACE",
    "F-PACE",
    "F-TYPE",
    "I-PACE",
    "S-TYPE",
    "SOVEREIGN",
    "V12",
    "X-TYPE",
    "X308",
    "XJRS",
    "XJSC",
    "XE",
    "XF",
    "XJ",
    "XJ-S",
    "XJ12",
    "XJ40",
    "XJR",
    "XK8",
    "XKR",
    "AUTRES",
  ],
  JEEP: [
    "CHEROKEE",
    "COMMANDER",
    "COMPASS",
    "COMPASS II",
    "GRAND  CHEROKEE",
    "PATRIOT",
    "RENEGADE",
    "WRANGLER",
    "AUTRES",
  ],
  KIA: [
    "CARENS",
    "CARNIVAL",
    "CEE D",
    "CERATO",
    "CLARUS",
    "LEO",
    "MAGENTIS",
    "NIRO",
    "OPIRUS",
    "OPTIMA",
    "PICANTO",
    "PRIDE",
    "PRIDE BREAK",
    "PRO CEE D",
    "RIO",
    "SEPHIA",
    "SHUMA",
    "SORENTO",
    "SOUL",
    "SPORTAGE",
    "STINGER",
    "STONIC",
    "VENGA",
    "XCEED",
    "AUTRES",
  ],
  KTM: ["X-BOW", "AUTRES"],
  LADA: [
    "110",
    "111",
    "1118",
    "112",
    "1200",
    "1300",
    "1500",
    "1600",
    "2105",
    "2107",
    "GRANTA",
    "KALINA",
    "KALINKA",
    "NATACHA",
    "NIVA",
    "PRIORA",
    "SAGONA",
    "SAMARA",
    "AUTRES",
  ],
  LAMBORGHINI: [
    "AVENTADOR",
    "COUNTACH",
    "DIABLO",
    "GALLARDO",
    "HURACAN",
    "JALPA",
    "LM",
    "MURCIELAGO",
    "URUS",
    "AUTRES",
  ],
  LANCIA: [
    "BETA",
    "COUPE",
    "DEDRA",
    "DELTA",
    "FLAVIA",
    "GAMMA",
    "GRAND VOYAGER",
    "HPE",
    "KAPPA",
    "LYBRA",
    "MUSA",
    "PHEDRA",
    "PRISMA",
    "THEMA",
    "THESIS",
    "TREVI",
    "VOYAGER",
    "Y",
    "Y10",
    "YPSILON",
    "ZETA",
    "AUTRES",
  ],
  "LAND ROVER": [
    "90",
    "DEFENDER",
    "DISCOVERY",
    "FREELANDER",
    "RANGE ROVER",
    "RANGE ROVER VELAR",
    "AUTRES",
  ],
  LEXUS: [
    "CT",
    "ES",
    "GS",
    "IS",
    "IS",
    "LC",
    "LS",
    "NX",
    "NX",
    "RC",
    "RC",
    "RX",
    "SC",
    "UX",
    "AUTRES",
  ],
  LIGIER: [
    "161",
    "162",
    "AMBRA",
    "BE SUN PROLINE",
    "BE TWO",
    "BE UP",
    "DUÉ",
    "FLEX",
    "FLEX L3",
    "IXO",
    "IXO TRECK",
    "JS",
    "JS RC",
    "JS50",
    "LYRA",
    "NOVA",
    "OPTIMA",
    "OPTIMAX",
    "X CUBE",
    "X-PRO",
    "X-TOO",
    "X-TOO II",
    "X-TOO MAX",
    "X-TOO R",
    "X-TOO RS",
    "X-TOO S",
    "AUTRES",
  ],
  LITTLE: ["EBOX", "LITTLE4", "AUTRES"],
  LOTUS: [
    "2-ELEVEN",
    "ECLAT",
    "ELAN",
    "ELISE",
    "ESPRIT",
    "EVORA",
    "EXCEL",
    "EXIGE",
    "AUTRES",
  ],
  MPM: ["PS160", "AUTRES"],
  MVS: ["VENTURI", "VENTURI", "AUTRES"],
  MAHINDRA: ["BOLERO", "GOA", "AUTRES"],
  MARUTI: ["800", "AUTRES"],
  MASERATI: [
    "222",
    "222 COUPE",
    "228",
    "3200 GT",
    "422",
    "430",
    "BITURBO",
    "COUPE",
    "GHIBLI",
    "GRANCABRIO",
    "GRANTURISMO",
    "LEVANTE",
    "MERAK",
    "QUATTROPORTE",
    "SHAMAL",
    "SPIDER CABRIOLET",
    "SPYDER",
    "AUTRES",
  ],
  MASTRETTA: ["MXT", "AUTRES"],
  MAYBACH: ["57", "62", "MAYBACH", "AUTRES"],
  MAZDA: [
    "121",
    "2",
    "3",
    "323",
    "5",
    "6",
    "626",
    "929",
    "CX-3",
    "CX-30",
    "CX-5",
    "CX-7",
    "DEMIO",
    "MPV",
    "MX-3",
    "MX-5",
    "MX-6",
    "PREMACY",
    "RX-7",
    "RX-8",
    "TRIBUTE",
    "XEDOS",
    "AUTRES",
  ],
  MCLAREN: [
    "540C",
    "570GT",
    "570S",
    "650S",
    "675LT",
    "MP4",
    "MCLAREN 720S",
    "P1",
    "AUTRES",
  ],
  MEGA: ["BREAK", "CABRIOLET", "CLUB", "CONCEPT", "FOURGON", "MEGA", "AUTRES"],
  MG: ["F", "MGF", "TF", "ZR", "ZS", "ZT", "AUTRES"],
  MIA: ["MIA", "AUTRES"],
  MICROCAR: [
    "CARGO",
    "COMORES",
    "DUÉ",
    "F8C",
    "FAMILY.1",
    "FAMILY.2",
    "LYRA",
    "M-PRO",
    "M8",
    "MC",
    "MC1",
    "MC1 FAMILY",
    "MC1.1",
    "MC1.2",
    "MC2",
    "MC2.1",
    "MC2.2",
    "MGO",
    "MGO SXI",
    "MADRAS",
    "SHERPA",
    "SHERPA YANMAR",
    "SHERPA ÉLECTRIQUE",
    "SPRINT",
    "VIRGO",
    "VIRGO 2",
    "VIRGO 3 TQM",
    "VIRGO ACTIV",
    "VIRGO FAMILY",
    "VIRGO HIGHLAND",
    "VIRGO III",
    "VIRGO LIBERTY",
    "VIRGO TQM",
    "AUTRES",
  ],
  MINI: ["COUNTRYMAN", "MINI", "MINI CABRIOLET", "PACEMAN", "AUTRES"],
  "MINI HUMMER": ["HX", "HX-T", "AUTRES"],
  MITSUBISHI: [
    "3000",
    "3000 GT",
    "ASX",
    "CARISMA",
    "COLT",
    "ECLIPSE",
    "ESTATE",
    "GALANT",
    "GRANDIS",
    "I-MIEV",
    "LANCER",
    "MONTERO",
    "OUTLANDER",
    "PAJERO",
    "PAJERO PININ",
    "SAPPORO",
    "SPACE",
    "SPACE RUNNER",
    "SPACE STAR",
    "SPACE WAGON",
    "AUTRES",
  ],
  MORGAN: [
    "4/4 SERIE",
    "MORGAN",
    "PLUS 4",
    "PLUS 8",
    "THREE WHEELER",
    "V6",
    "AUTRES",
  ],
  MOSKVITCH: ["ALEKO", "AUTRES"],
  NISSAN: [
    "100",
    "100 NX",
    "200",
    "200 SX",
    "300",
    "300ZX",
    "350 Z ROADSTER",
    "350Z",
    "370Z",
    "ALMERA",
    "ARIYA",
    "BLUEBIRD",
    "CEDRIC",
    "CHERRY",
    "CUBE",
    "EVALIA",
    "GT-R",
    "INTERSTAR",
    "JUKE",
    "LEAF",
    "MAXIMA",
    "MICRA",
    "MURANO",
    "NAVARA",
    "NOTE",
    "NV200",
    "NV300 COMBI",
    "NV400 COMBI",
    "PATHFINDER",
    "PATROL",
    "PIXO",
    "PRAIRIE",
    "PRIMASTAR",
    "PRIMERA",
    "PULSAR",
    "QASHQAI",
    "SERENA",
    "SILVIA",
    "STANZA",
    "SUNNY",
    "TERRANO",
    "VANETTE",
    "X-TRAIL",
    "Z",
    "ZX",
    "AUTRES",
  ],
  OLDSMOBILE: ["DELTA", "OMEGA", "AUTRES"],
  PGO: ["180 CEVENNES", "HEMERA", "SPEEDSTER II", "AUTRES"],
  PIAGGIO: ["M500", "PORTER PV", "AUTRES"],
  "POLSKI/FSO": ["125", "1300", "1500", "ATOU", "CARO", "POLONEZ", "AUTRES"],
  PONTIAC: ["FIREBIRD", "TRANS-SPORT", "AUTRES"],
  PORSCHE: [
    "911",
    "918",
    "918SPYDER",
    "924",
    "928",
    "930",
    "944",
    "968",
    "BOXSTER",
    "CARRERA",
    "CARRERA GT",
    "CAYENNE",
    "CAYMAN",
    "MACAN",
    "PANAMERA",
    "TAYCAN",
    "AUTRES",
  ],
  PROTON: ["300ER", "400ER", "LIFTBACK", "SEDAN", "AUTRES"],
  "ROLLS-ROYCE": [
    "CAMARGUE",
    "CORNICHE",
    "DAWN",
    "FLYING SPUR",
    "GHOST",
    "PARK WARD",
    "PHANTOM",
    "SILVER",
    "TOURING",
    "WRAITH",
    "AUTRES",
  ],
  ROVER: [
    "100",
    "100 CABRIOLET",
    "111",
    "114",
    "200",
    "213",
    "214",
    "214I",
    "216",
    "216I",
    "218",
    "220",
    "2300",
    "2400",
    "25",
    "2600",
    "400",
    "414",
    "416",
    "418",
    "420",
    "45",
    "600",
    "620",
    "623",
    "75",
    "800",
    "820",
    "820NV",
    "825",
    "825NV",
    "827",
    "827NV",
    "ESTATE",
    "MAESTRO",
    "METRO",
    "MINI",
    "MONTEGO",
    "ROVER",
    "SD1",
    "STREETWISE",
    "AUTRES",
  ],
  SAAB: ["9-3", "9-3X", "9-4X", "9-5", "9-7X", "900", "9000", "AUTRES"],
  SANTANA: [
    "NAIROBI",
    "PS 10",
    "S 350",
    "S 410",
    "S 413",
    "S300",
    "SAMURAI",
    "VITARA",
    "AUTRES",
  ],
  SAVEL: [
    "0GORA2",
    "AGORA 2",
    "AGORA 2B",
    "AGORA LUXE 9 KW",
    "AGORA9/2",
    "SISMA",
    "SPACIA",
    "SPACIA 2",
    "SPACIA9/2",
    "SPACIA9/4",
    "VIGANE",
    "AUTRES",
  ],
  SEAT: [
    "ALHAMBRA",
    "ALTEA",
    "ARONA",
    "AROSA",
    "ATECA",
    "CORDOBA",
    "EXEO",
    "FURA",
    "IBIZA",
    "IBIZANS",
    "INCA",
    "LEON",
    "MALAGA",
    "MARBELLA",
    "MII",
    "RONDA",
    "RONDAP",
    "TARRACO",
    "TERRA",
    "TOLEDO",
    "AUTRES",
  ],
  SHUANGHUAN: ["CEO", "AUTRES"],
  "SIMPA JDM": [
    "ABACA",
    "ALBIZIA",
    "ALOES",
    "ORANE",
    "ROXSY",
    "TITANE",
    "X-TREM",
    "X5",
    "X5 CROISIÈRE",
    "XHEOS",
    "AUTRES",
  ],
  SKODA: [
    "105",
    "1050",
    "120",
    "130",
    "CITIGO",
    "COUPE",
    "FABIA",
    "FAVORIT",
    "FELICIA",
    "FORMAN",
    "KAMIQ",
    "KAROQ",
    "KODIAQ",
    "OCTAVIA",
    "RAPID",
    "ROOMSTER",
    "SCALA",
    "SUPERB",
    "YETI",
    "AUTRES",
  ],
  SMART: [
    "CITY",
    "FORFOUR",
    "FORTWO",
    "FORTWOCABRIOLET",
    "FORTWOCOUPE",
    "ROADSTER",
    "SMARTCABRIOLET",
    "SMARTCOUPE",
    "SMARTCROSSBLADE",
    "SMARTROADSTER",
    "SMARTROADSTERCOUPE",
    "AUTRES",
  ],
  SSANGYONG: [
    "ACTYON",
    "FAMILY",
    "KORANDO",
    "KYRON",
    "MUSSO",
    "REXTON",
    "RODIUS",
    "TIVOLI",
    "TIVOLI XLV",
    "AUTRES",
  ],
  SUBARU: [
    "B9 TRIBECA",
    "BRZ",
    "E 12",
    "FORESTER",
    "G3X JUSTY",
    "IMPREZA",
    "JUSTY",
    "LEGACY",
    "LEVORG",
    "OUTBACK",
    "SVX",
    "TREZIA",
    "TRIBECA",
    "VANILLE",
    "WRX STI",
    "XV",
    "AUTRES",
  ],
  SUZUKI: [
    "ALTO",
    "BALENO",
    "CELERIO",
    "GRAND VITARA",
    "IGNIS",
    "JIMNY",
    "KIZASHI",
    "LIANA",
    "SAMURAI",
    "SPLASH",
    "SWIFT",
    "SX4",
    "VITARA",
    "WAGON",
    "WAGON R",
    "X-90",
    "AUTRES",
  ],
  TALBOT: [
    "1510",
    "HORIZON",
    "MURENA",
    "RANCHO",
    "SAMBA",
    "SOLARA",
    "TAGORA",
    "AUTRES",
  ],
  TAVRIA: ["1100", "AUTRES"],
  TESLA: ["MODEL 3", "MODEL S", "MODEL X", "MODEL Y", "AUTRES"],
  TOYOTA: [
    "4-RUNNER",
    "AURIS",
    "AVENSIS",
    "AYGO",
    "C-HR",
    "CAMRY",
    "CARINA",
    "CELICA",
    "COROLLA",
    "CRESSIDA",
    "ESCAPE",
    "GRSUPRA",
    "GT86",
    "HI ACE",
    "IQ",
    "LAND CRUISER",
    "LC.TD",
    "LEXUS",
    "LITE-ACE",
    "MIRAI",
    "MODEL",
    "MODELE F",
    "MR",
    "MR2",
    "PASEO",
    "PICNIC",
    "PREVIA",
    "PRIUS",
    "PRIUS+",
    "PROACE COMBI",
    "PROACE VERSO",
    "RAV 4",
    "RUNNER",
    "STARLET",
    "SUPRA",
    "TERCEL",
    "URBAN CRUISER",
    "VERSO",
    "YARIS",
    "AUTRES",
  ],
  TRIUMPH: ["ACCLAIM", "AUTRES"],
  TVR: ["350I", "CERBERA", "CHIMAERA", "GRIFFITH", "S4", "AUTRES"],
  UMM: ["ALTER", "AUTRES"],
  VENTURI: [
    "400",
    "ATLANTIQUE",
    "CABRIO TRANSCUP",
    "COUPE",
    "FÉTISH",
    "SPIDER",
    "AUTRES",
  ],
  VOLVO: [
    "240",
    "240 BREAK",
    "244",
    "245",
    "264",
    "265",
    "340 / 360",
    "343",
    "345",
    "440 / 460",
    "480",
    "740",
    "760",
    "780",
    "850",
    "940 / POLAR",
    "960",
    "C30",
    "C70",
    "M40",
    "S40",
    "S60",
    "S70",
    "S80",
    "S90",
    "V40",
    "V50",
    "V60",
    "V70",
    "V90",
    "XC40",
    "XC60",
    "XC70",
    "XC90",
    "AUTRES",
  ],
  ZAZ: ["TRAVIA", "AUTRES"],
  ZASTAVA: ["1100", "FLORIDA", "YUGO", "AUTRES"],
  AUTRES: ["AUTRES"],
};

export const getBrands = () => {
  return Object.keys(brands).map((brand) => brand.toUpperCase());
};

export const getModels = (brand: string) => {
  return brands.hasOwnProperty(brand)
    ? brands[brand].map((model) => model.toUpperCase())
    : [];
};
