import React from "react";

const MySvgComponent: React.FC = () => (
  <svg
    id="Calque_1"
    data-name="Calque 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 396.71 87.99"
    aria-labelledby="title"
  >
    <title id="title">Cars For Pro Logo</title>
    <style>
      {`
        .cls-1 {
          letter-spacing: 0em;
        }
        .cls-2 {
          font-family: 'Futura-Medium', Futura, sans-serif;
          font-size: 53.09px;
          font-weight: 500;
        }
        .cls-2, .cls-3 {
          fill: #277af6;
        }
        .cls-3 {
          stroke-width: 0px;
        }
      `}
    </style>
    <g>
      <path
        className="cls-3"
        d="M51.56,54.18c0-2.84,5.35-9.02,5.35-10.83,0-1.13-1.18-1.68-1.18-1.68,6.01,0-3.09,12.52-4.17,12.52Z"
      />
      <path
        className="cls-3"
        d="M91.16,43.64c-7.71,0-9.56-2.56-13-5.13,6.05,0,13,5.13,13,5.13Z"
      />
      <path
        className="cls-3"
        d="M38.64,23.88c-1.23.12-3.34.41-4.14,1.01,4.9,2.5,14.78,9.89,14.78,9.89,0,0,9.01-.68,11.83-.6l-.05-.02c20.63.26,35.18,6.85,36.49,11.44l-1.92.32c-.28.04-.5.23-.61.49l-2.54,6.8c-.07.19.02.4.2.49l2.27,1.04c-.47.81-.93,1.32-1.34,1.32l-9.57.07c.88-2.34,3.21-8.18,4.69-8.18,2.2,0,4.15-.57,4.15-.57,0,0-5-1.12-5.64-1.12-.55,0-3,7.25-3.88,9.89l-3.77.02c.56-1.31.88-2.76.88-4.27,0-5.96-4.83-10.8-10.79-10.8h-.92c-2.98,0-5.68,1.22-7.63,3.17-1.96,1.95-3.17,4.65-3.17,7.63,0,1.58.34,3.07.96,4.42-16.98-1.27-32.77-2.08-40.25.3,5.42,18.15,22.24,31.38,42.15,31.38,24.3,0,44-19.69,44-43.99S85.1,0,60.81,0c-16.69,0-30.69,8.37-38.14,22.07,5.31-.12,10.7.45,15.97,1.8ZM77.34,56.41c-.42.77-.95,1.47-1.57,2.09-1.69,1.69-4.03,2.74-6.61,2.74-3.49,0-6.52-1.89-8.12-4.71-.8-1.37-1.25-2.95-1.25-4.65,0-2.6,1.05-4.93,2.74-6.63,1.69-1.69,4.03-2.74,6.63-2.74s4.92,1.05,6.61,2.74c1.69,1.69,2.74,4.03,2.74,6.63,0,1.64-.42,3.18-1.17,4.52Z"
      />
      <path
        className="cls-3"
        d="M46.72,34.77s-33.69-.78-39.48-1.04c-5.77-.26-7.25-3.53-7.25-3.53,0,0,7.5-5.31,22.68-5.39,13.71-.07,18.87,6.42,24.04,9.96Z"
      />
    </g>
    <text className="cls-2" transform="translate(128.04 63.57)">
      <tspan x="0" y="0">
        Cars
      </tspan>
      <tspan className="cls-1" x="110.42" y="0">
        F
      </tspan>
      <tspan x="136.94" y="0">
        orP
      </tspan>
      <tspan className="cls-1" x="217.37" y="0">
        r
      </tspan>
      <tspan x="236.76" y="0">
        o
      </tspan>
    </text>
  </svg>
);

export default MySvgComponent;
