import React from "react";
import "../styles/Auth.css";

import backgroundImage from "../images/connexion_desktop.jpg";
import backgroundImageMobile from "../images/connexion_mobile.jpg";

import { useIsMobile } from "../utils/mobile";

type AuthProps = {
  children: JSX.Element;
};

export default function Auth({ children }: AuthProps) {
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        backgroundImage: `url(${
          isMobile ? backgroundImageMobile : backgroundImage
        })`, // Using the image
        backgroundSize: "cover", // Makes sure the image covers the entire div
        backgroundPosition: "center", // Centers the image
        height: "100vh", // Full screen height
        width: "100%", // Full screen width
      }}
      className="login-parent"
    >
      {children}
    </div>
  );
}
