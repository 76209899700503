import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import { TbaseReducer } from "../../types/reducersType";

import { useAppDispatch } from "../../reducers";

import {
  addAccount,
  editAccount,
  deleteAccount,
} from "../../actions/adminAction";

import { departments } from "../../utils/department";

import { TcurrentAccount } from "../../types/admin/adminType";

export default function Acounts() {
  const dispatch = useAppDispatch();

  const { accounts } = useSelector((state: TbaseReducer) => state.adminReducer);

  const [currentAccount, setCurrentAccount] = useState<TcurrentAccount>({
    isModal: false,
    id: -1,
    name: "",
    email: "",
    phone: "",
    siret: "",
    notifications: [],
    password: "",
    addAd: false,
  });

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = accounts.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(accounts.length / itemsPerPage);

  // @ts-ignore
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % accounts.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>Liste des comptes</h1>
        <button
          style={{ width: "auto" }}
          onClick={() =>
            setCurrentAccount({
              id: -1,
              name: "",
              email: "",
              phone: "",
              siret: "",
              notifications: departments.map(
                (department) => department.num_dep
              ),
              password: "",
              isModal: true,
              addAd: false,
            })
          }
        >
          Ajouter un compte
        </button>
      </div>
      <div className="ads-list" style={{ overflowX: "auto" }}>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Nom</th>
              <th>E-mail</th>
              <th>Téléphone</th>
              <th>Siret</th>
              {/* <th>Rôle</th> */}
              {/* <th>Notification(s)</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((account, idx) => (
              <tr key={idx}>
                <th>{itemOffset + idx + 1}</th>
                <td>{account.name}</td>
                <td>{account.email}</td>
                <td>{account.phone}</td>
                <td>{account.siret}</td>
                {/* <td>{account.role === 1 ? "Adm" : "Us"}</td> */}
                {/* <td>
                  <span
                    style={{
                      overflow: "hidden",
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "100px",
                    }}
                  >
                    {[...account.notifications].sort().join(", ")}
                  </span>
                </td> */}
                <td>
                  {account.role === 1 ? null : (
                    <div style={{ display: "flex", gap: 10 }}>
                      <button
                        onClick={() =>
                          setCurrentAccount({
                            ...currentAccount,
                            id: account.id,
                            name: account.name,
                            email: account.email,
                            notifications: account.notifications || [],
                            addAd: account.addAd,
                            isModal: true,
                          })
                        }
                      >
                        Modifier
                      </button>
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Êtes-vous sûr de vouloir supprimer ce compte ?"
                            )
                          ) {
                            dispatch(deleteAccount(account.id));
                          }
                        }}
                      >
                        Supprimer
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel="Suivant"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="Précèdent"
          renderOnZeroPageCount={null}
        />
      </div>
      {currentAccount.isModal && (
        <div id="myModal" className="modal">
          <div className="modal-content">
            <span
              className="close"
              onClick={() =>
                setCurrentAccount({
                  ...currentAccount,
                  isModal: false,
                })
              }
            >
              &times;
            </span>
            <h2>
              {currentAccount.id > 0 ? "Modifier" : "Créer"} l'utilisateur
            </h2>

            <form
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault();

                if (currentAccount.id > 0) {
                  dispatch(
                    editAccount(
                      currentAccount.id,
                      currentAccount.name,
                      currentAccount.email,
                      currentAccount.phone,
                      currentAccount.siret,
                      currentAccount.notifications,
                      currentAccount.addAd,
                      currentAccount.password
                    )
                  );
                } else {
                  dispatch(
                    addAccount(
                      currentAccount.name,
                      currentAccount.email,
                      currentAccount.phone,
                      currentAccount.siret,
                      currentAccount.notifications,
                      currentAccount.addAd,
                      currentAccount.password
                    )
                  );
                }

                setCurrentAccount({
                  id: -1,
                  name: "",
                  email: "",
                  phone: "",
                  siret: "",
                  notifications: departments.map(
                    (department) => department.num_dep
                  ),
                  password: "",
                  addAd: false,
                  isModal: false,
                });
              }}
              className="add-ad-form"
            >
              <div className="form-group">
                <label>Nom</label>
                <input
                  type="text"
                  value={currentAccount.name}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      name: e.target.value,
                    });
                  }}
                />
                <label>E-mail</label>
                <input
                  type="text"
                  value={currentAccount.email}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      email: e.target.value,
                    });
                  }}
                />
                <label>N° téléphone</label>
                <input
                  type="text"
                  value={currentAccount.phone}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      phone: e.target.value,
                    });
                  }}
                />
                <label>SIRET</label>
                <input
                  type="text"
                  value={currentAccount.siret}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      siret: e.target.value,
                    });
                  }}
                />
                <label style={{ whiteSpace: "nowrap" }}>
                  <input
                    type="checkbox"
                    onClick={() => {
                      setCurrentAccount({
                        ...currentAccount,
                        addAd: !currentAccount.addAd,
                      });
                    }}
                    checked={currentAccount.addAd}
                  />
                  Ajout d'annonce
                </label>
                <label
                  style={{
                    marginTop: 10,
                  }}
                >
                  Notifications(s)
                </label>
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    flexWrap: "wrap",
                    marginBottom: 10,
                  }}
                >
                  {departments.map((department, key) => (
                    <label style={{ whiteSpace: "nowrap" }}>
                      <input
                        type="checkbox"
                        onClick={() => {
                          let tmpNotifications: string[] = [];
                          if (
                            currentAccount.notifications.includes(
                              department.num_dep
                            )
                          ) {
                            tmpNotifications =
                              currentAccount.notifications.filter(
                                (num) => num !== department.num_dep
                              );
                          } else {
                            tmpNotifications = [
                              ...currentAccount.notifications,
                              department.num_dep,
                            ];
                          }

                          setCurrentAccount({
                            ...currentAccount,
                            notifications: tmpNotifications,
                          });
                        }}
                        checked={currentAccount.notifications.includes(
                          department.num_dep
                        )}
                      />
                      {department.dep_name} ({department.num_dep})
                    </label>
                  ))}
                </div>
                <label>
                  Mot de passe
                  {currentAccount.id > 0 ? " (si vide = non modifié)" : ""}
                </label>
                <input
                  type="text"
                  value={currentAccount.password}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      password: e.target.value,
                    });
                  }}
                />
                <button type="submit">
                  {currentAccount.id > 0 ? "Modifier" : "Créer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
