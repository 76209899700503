export const _fetch = (
  url: string,
  type: string,
  body: string,
  dispatch: any
) => {
  dispatch({ type: "START_LOADER" });

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      dispatch({ type: "END_LOADER" });
      dispatch({
        type,
        body: JSON.parse(body),
        ...res,
      });
    })
    .catch((err) => {
      dispatch({ type: "END_LOADER" });

      console.log("API failed");
      console.log("Une erreur est survenue");
      console.log(err);
    });
};
