import { _fetch } from "../utils/fetch";

export const getOffers = () => {
  return (dispatch: any) => {
    _fetch("/api/offer/get-offers", "GET_OFFERS", JSON.stringify({}), dispatch);
  };
};

export const addOffer = (adToken: string, price: number, comment: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/offer/add-offer",
      "ADD_OFFER",
      JSON.stringify({ adToken, price, comment }),
      dispatch
    );
  };
};

export const updateOffer = (offerId: number, status: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/offer/update-offer",
      "UPDATE_OFFER",
      JSON.stringify({ offerId, status }),
      dispatch
    );
  };
};
